import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilterSub from "../components/jobPostsFilterSub"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"
import JobsNavFohm from "../components/jobsNavFohm"

const RestaurantManagement = () => (
  <Layout jobPage={true} splashPage={false} pageTitle="VACANCIES - CHEFS">
    <Seo title="Vacancies chefs" />
    <JobsNav link={"front"} />
    <JobsNavFohm link={"restaurant-management"} />
    <JobPostsFilterSub category={9} subcategory={"Restaurant Management"} />
  </Layout>
)

export default RestaurantManagement
